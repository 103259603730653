import React from 'react';
import c from 'classnames';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import { useI18next } from 'gatsby-plugin-react-i18next';
import HorizontalTitleSeparator from '../HorizontalTitleSeparator/HorizontalTitleSeparator';
import CopyText from '../CopyText';

const services = ['serviceItemOne', 'serviceItemTwo', 'serviceItemThree', 'serviceItemFour'];

const companyServices = ['serviceItemCompanyOne', 'serviceItemCompanyTwo'];

const Services: React.FC = () => {
  const { t } = useI18next();

  return (
    <Container section>
      <HorizontalTitleSeparator title={t('servicesBigTitle')} additionalClass="mt-16" />
      <CopyText variant="copy-2" additionalClass="my-16">
        {t('servicesOne')}
      </CopyText>
      <TitleSection noSeparator subtitle={t('servicesTitle')} className="py-10" />
      {services.map((service, key) => (
        <div key={key} className={c('py-10 flex w-full', key === 0 && 'pt-0')}>
          <div
            style={{
              width: 25,
              height: 25,
              minWidth: 25,
              minHeight: 25,
              borderRadius: '100%',
              border: '1px solid #312e91',
              marginRight: '10px',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="#312e91" viewBox="0 0 24 24">
              <path d="M18 8.507L16.59 7 10 13.996l-2.58-2.729L6 12.764 10 17l8-8.493z" />
            </svg>
          </div>
          <p>{t(service)}</p>
        </div>
      ))}
      <TitleSection noSeparator subtitle={t('serviceItemCompany')} className="mt-32 mb-16" />
      {companyServices.map((service, idx) => (
        <div key={idx} className={c('py-10 flex  w-full', idx === 0 && 'pt-0')}>
          <div
            style={{
              width: 25,
              height: 25,
              minWidth: 25,
              minHeight: 25,
              borderRadius: '100%',
              border: '1px solid #312e91',
              marginRight: '10px',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="#312e91" viewBox="0 0 24 24">
              <path d="M18 8.507L16.59 7 10 13.996l-2.58-2.729L6 12.764 10 17l8-8.493z" />
            </svg>
          </div>
          <p>{t(service)}</p>
        </div>
      ))}
    </Container>
  );
};

export default Services;
