import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Services from 'components/Services';
import { graphql } from 'gatsby';

const ServicesPage: React.FC = () => (
  <Layout id="services">
    <SEO title="Services" />
      <Services />
  </Layout>
);

export default ServicesPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
